#root {
  height: 100%;
}

.main-outlet {
  display: grid;
  height: 100%;
}

.main-outlet__container {
  display: grid;
  grid-template-columns: 100%;
  background: linear-gradient(42.4deg, #5538d3 0%, #863ab5 45%, #482dbd 100%);
}

.route-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
